<template>
  <div v-editable="editing && blok" class="sb-pagelink mb-[1rem] mr-[1rem]">

    <NuxtLink
      :to="
        blok.link.linktype == 'url' ? blok.link.url : localePath(getLink(blok.link))
      "
      :target="blok.link.linktype == 'url' && blok.link.url.indexOf('javascript:') != 0 && blok.link.url != '/' ? '_blank' : '_self'"
      :class="[
        { button: blok.is_button },
        blok.is_button && `button--${blok.button_type}`,
      ]"
      >{{ (blok.label !='' && blok.label) || (blok.link.story && blok.link.story.name) }}</NuxtLink
    >
  </div>
</template>
<style lang="scss">
.sb-pagelink{
   display: inline-block;
  
  a{
    text-align: center;
    width: 100%;
  }
}
  
</style>
<script setup>
const { handleInternalLink } = useConfig();
const localePath = useLocalePath()
const { blok } = defineProps({ blok: Object });
const { editing } = useSettings();

// console.log(blok)
const getLink = function (link) {
  // console.log(handleInternalLink(link.story.full_slug),"--",localePath(handleInternalLink(link.story.full_slug,true)))
  if (link.linktype == "story") {
    if (link.story) return handleInternalLink(link.story.full_slug);
  } else if (link.linktype == "url") {
    return link.url;
  }
};
</script>
